<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_enteringAchieveAdd" />
      <!-- （画面名）入庫実績登録-->
      <v-container fluid>
        <!-- 検索項目-->
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <div v-if="suppliersSelected.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="suppliersSelected"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ suppliersSelected }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- 入荷元-->
            <v-text-field
              dense
              readonly
              v-model="arrivalSelected"
              :label="$t('label.lbl_arrival')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="date-style">
            <!-- 入荷実績日-->
            <v-text-field
              readonly
              dense
              v-model="arrivalAchieveDate"
              :label="$t('label.lbl_arrivalacheivementDate')"
              class="txt-single"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="textbox-arrivalNo">
            <!-- 入荷No-->
            <v-text-field
              readonly
              dense
              class="txt-single"
              v-model="txt_slipNo"
              maxlength="50"
              :label="$t('label.lbl_slipNo')"
            />
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- 有償無償-->
            <v-text-field
              readonly
              dense
              v-model="isPaidNm"
              :label="$t('label.lbl_isPaid')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer" style="float: left">&nbsp;</span>

          <div class="search-autocomplete" style="float: left">
            <!-- 処理区分-->
            <v-text-field
              dense
              readonly
              v-model="processDivSelected"
              :label="$t('label.lbl_processingdivision')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
            ></v-text-field>
          </div>
        </v-row>
        <v-row>
          <!-- 倉庫-->
          <div class="search-autocomplete first-search-item" style="float: left">
            <v-autocomplete
              dense
              v-model="warehouseSelected"
              :items="warehouseList"
              :label="$t('label.lbl_warehouse')"
              persistent-hint
              :hint="setWarehouse()"
            >
              <template slot="item" slot-scope="data">
                <span class="auto-txt-size"> {{ data.item.text }} </span>
              </template>
            </v-autocomplete>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>
        </v-row>
        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 一括反映ボタン-->
              <v-btn class="other-btn" value="1" v-on:click="collectiveReflection">
                {{ $t("btn.btn_collectiveReflection") }}
              </v-btn>
              <!-- 登録ボタン-->
              <v-btn class="post-btn" value="1" v-on:click="rcvAchAdd">
                {{ $t("btn.btn_insert") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="deleteStock"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- ヘッダーロット -->
            <template v-slot:[`header.lot`]="{ header }">
              <v-row>
                <v-col>
                  <div>{{ header.text }}</div>
                  <div>
                    {{
                      $t("label.lbl_dueDate") +
                      "／" +
                      $t("label.lbl_lotSubkey1") +
                      "／" +
                      $t("label.lbl_lotSubkey2")
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.warehouse`]="{ item, index }">
              <div id="table-actualQuantity" v-if="item.deleteFlg == 1">
                <td>
                  <div v-if="item.displayWarehouse.length > 5">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="table-warehouse"
                          style="max-width: 250px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.displayWarehouse }}
                        </div>
                      </template>
                      <span>{{ item.displayWarehouse }} </span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <div>
                      {{ item.displayWarehouse }}
                    </div>
                  </div>
                </td>
              </div>
              <div v-else>
                <!-- 倉庫-->
                <v-autocomplete
                  v-model="item.warehouse"
                  :items="warehouseList"
                  :hint="checkWarehouse(item)"
                  persistent-hint
                  @change="changeWarehouse(index)"
                  dense
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <template v-slot:[`item.location`]="{ item, index }">
              <div id="table-location" v-if="item.deleteFlg == 1">
                <td>
                  <div v-if="item.displayLocation.length > 5">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="table-location"
                          style="max-width: 250px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.displayLocation }}
                        </div>
                      </template>
                      <span>{{ item.displayLocation }} </span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <div>{{ item.displayLocation }}</div>
                  </div>
                </td>
              </div>
              <div v-else>
                <!-- ロケ-->
                <v-autocomplete
                  v-model="item.location"
                  :items="item.locationList"
                  :hint="checkLocation(item, index)"
                  @change="(event) => changeLocation(event, item, index)"
                  :search-input.sync="item.locaSearch"
                  persistent-hint
                  dense
                  :error-messages="item.locationValidMsg"
                >
                  <!-- アイテム一覧の表示 -->
                  <template slot="item" slot-scope="data">
                    <span class="test">
                      {{ data.item.text }}
                    </span>
                  </template>
                  <!-- * -->
                </v-autocomplete>
              </div>
            </template>

            <!-- ヘッダーチェックボックス -->
            <template v-slot:[`header.check`]="{ header }">
              <input
                type="checkbox"
                :checked="isAllSelected"
                :value="header.check"
                @click="selectAllCheck"
                style="transform: scale(2)"
              />
            </template>

            <!-- 一覧チェックボックス -->
            <template v-slot:[`item.check`]="{ item, index }">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                @click="checkList(item, index)"
                style="transform: scale(2)"
                :disabled="item.deleteFlg === '1'"
              />
            </template>

            <!-- 品番/付帯 -->
            <template v-slot:[`item.itemCd`]="{ item }">
              <div class="listNo-style">{{ item.itemCd }}</div>
              <div class="listNo-style">{{ item.incidental }}</div>
            </template>
            <!-- ロット/S/N-->
            <!-- 期限日／付属キー１／付属キー２-->
            <template v-slot:[`item.lot`]="{ item }">
              <!-- S/Nが15文字以上だった場合ツールチップで表示 -->
              <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-truncate" style="max-width: 350px" v-bind="attrs" v-on="on">
                      {{ item.lot }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <span>{{ item.lot }}<br />{{ item.dueDate }}</span>
                </v-tooltip>
              </div>
              <!-- serialが15文字以下だった場合ツールチップ無しで表示 -->
              <div v-else>
                {{ item.lot }}<br />
                {{ item.dueDate }}
              </div>
            </template>
            <template v-slot:[`item.acheieveQty`]="{ item, index }">
              <div id="table-actualQuantity" v-if="item.deleteFlg == 1">
                <!-- 実績数量 -->
                {{ item.oriAcheieveQty }}
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  v-model="item.acheieveQty"
                  class="input-number"
                  @change="changeAcheive(item, index)"
                  @click="clickPop(item.acheieveQty)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheck,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'caseSuCheckisNumber' + index"
                />
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 戻るボタンのダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :firstPageFlag="infoDialog.firstPageFlag"
          :homePageFlag="infoDialog.homePageFlag"
          :entAchSerchFlg="infoDialog.entAchSerchFlg"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
// import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: "EnteringAchieveAdd",
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    inputLocation: "",
    loadingCounter: 0,
    // バリデーションチェックリスト
    editedList: [],
    // 取引先
    suppliersSelected: "",
    // 入荷元
    arrivalSelected: "",
    // 入荷No
    txt_slipNo: "",
    // 入荷実績日
    arrivalAchieveDate: "",
    // 有償無償
    isPaidNm: "",
    // 倉庫
    warehouseSelected: "",
    warehouseList: [],
    // ロケ
    locationSelected: "",
    locationList: [],
    // 処理区分
    processDivSelected: "",
    // メニュー
    openMenu: null,
    // メッセージダイアログ
    dialogMessage: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 一覧index
    editedIndex: -1,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    simpleDialog: false,
    // メッセージダイアログ
    infoDialog: {
      title: "",
      message: "",
      firstPageFlag: false,
      homePageFlag: false,
      entAchSerchFlg: false,
    },
    productListIdx: -1,
    // 削除リスト
    deleteList: [],
    // アラート表示
    alertMessage: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // ロケ有効期間外エラーメッセージ
    locationValidMsg: "",
    // ロケ検索
    // locaSearch: "",
    // ヘッダ
    headerItems: [
      { text: "選択", value: "check", width: "4%", align: "center", sortable: false },
      // 入荷No
      {
        text: i18n.tc("label.lbl_slipDetailNo"),
        value: "arrivalNo",
        width: "7%",
        align: "left",
        sortable: true,
      },
      {
        // 倉庫
        text: i18n.tc("label.lbl_warehouse"),
        value: "warehouse",
        width: "13%",
        align: "center",
        sortable: false,
      },
      {
        // ロケーション
        text: i18n.tc("label.lbl_location"),
        value: "location",
        width: "13%",
        align: "center",
        class: "asta",
        sortable: false,
      },
      {
        // 品番
        text: i18n.tc("label.lbl_productCode"),
        value: "itemCd",
        width: "10%",
        align: "left",
        sortable: false,
      },
      {
        // 品名
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // ロット／S/N
      // 期限日／付属キー１／付属キー２
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "16%",
        align: "left",
      },
      {
        // 指示数量
        text: i18n.tc("label.lbl_indicatedQuantity"),
        value: "productQty",
        width: "9%",
        align: "center",
      },
      {
        // 実績数量
        text: i18n.tc("label.lbl_achieveQuantity"),
        value: "acheieveQty",
        width: "9%",
        align: "center",
      },
    ],
    inputList: [],
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheck: (value) =>
        0 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMoreOne"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
    },
  }),

  created() {
    // 実績登録画面で必要な値
    // 取引先(実績登録画面表示用)
    this.suppliersSelected = this.$route.params.suppliersNm;
    // 入荷元名（実績登録画面表示用）
    this.arrivalSelected = this.$route.params.arrivalNm;
    // 入荷実績日
    this.arrivalAchieveDate = this.$route.params.arrivalAchieveDate;
    // 入荷No
    this.txt_slipNo = this.$route.params.arrivalNo;
    // 有償無償名
    this.isPaidNm = this.$route.params.isPaidNm;
    // 処理区分名
    this.processDivSelected = this.$route.params.processDivSelected;
    // 入荷伝票SID
    this.inListSid = this.$route.params.inListSid;
    // 更新日時 TODO:入庫実績登録検索画面で取得する
    this.updateDatetime = this.$route.params.updateDatetime;
    // 処理区分
    this.processDiv = this.$route.params.processDiv;
    // 責任区分TODO:入庫実績登録検索画面で取得する
    this.blameDiv = this.$route.params.blameDiv;
    // 入荷元SID
    this.fromSid = this.$route.params.fromSid;
    // 有償無償区分
    this.freeCostDiv = this.$route.params.freeCostDiv;

    // 入庫実績登録（検索）の検索条件
    // 取引先SID
    this.searchSuppliersSelected = this.$route.params.suppliersSelected;
    // 入荷元SID
    this.searchArrivalSelected = this.$route.params.arrivalSelected;
    // 入荷No
    this.searchArrivalNo = this.$route.params.searchArrivalNo;
    // 入荷予定日From
    this.searchArrivalScheduleDateFrom = this.$route.params.arrivalScheduleDateFrom;
    // 入荷予定日TO
    this.searchArrivalScheduleDateTo = this.$route.params.arrivalScheduleDateTo;
    // 入荷予定日FromCal
    this.searchArrivalScheduleDateFromCal = this.$route.params.arrivalScheduleDateFromCal;
    // 入荷予定日ToCal
    this.searchArrivalScheduleDateToCal = this.$route.params.arrivalScheduleDateToCal;
    // 画面遷移判断フラグ
    this.checkFlg = this.$route.params.checkFlg;
    // 倉庫マスタ取得
    this.getWarehouse();
    // 入庫指示取得
    this.getDetail();
  },
  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(入荷予定一覧から値受け取る)
     */
    init() {
      // ceatedにて記載
    },

    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 入庫実績登録検索画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;
        // 画面遷移判断フラグ
        this.checkFlg = true;
        this.$router.push({
          name: appConfig.MENU_ID.P_ENT_ACTUAL_SERCH,
          params: {
            // 取引先SID
            searchSuppliersSelected: this.searchSuppliersSelected,
            // 入荷元SID
            searchArrivalSelected: this.searchArrivalSelected,
            // 入荷No
            searchArrivalNo: this.searchArrivalNo,
            // 入荷予定日From
            searchArrivalScheduleDateFrom: this.searchArrivalScheduleDateFrom,
            // 入荷予定日To
            searchArrivalScheduleDateTo: this.searchArrivalScheduleDateTo,
            // 入荷予定日FromCal
            searchArrivalScheduleDateFromCal: this.searchArrivalScheduleDateFromCal,
            // 入荷予定日ToCal
            searchArrivalScheduleDateToCal: this.searchArrivalScheduleDateToCal,
            // 画面遷移フラグ
            checkFlg: this.checkFlg,
            // 入荷元ドロップダウン
            searchArrivalList: this.$route.params.arrivalList,
            // 入荷元ドロップダウンヒント
            arrivalNm: this.$route.params.arrivalNm,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入力カンマ処理 実績数量
     */
    changeAcheive(val, index) {
      var acheieveQty = commonUtil.zen2han(val.acheieveQty).replaceAll(",", "");
      acheieveQty = acheieveQty.replace(/[^0-9]/gi, "");
      if (acheieveQty.length > 9) {
        acheieveQty = acheieveQty.substring(0, 9);
      }
      if (!isNaN(Number(acheieveQty))) {
        this.inputList[index].acheieveQty = commonUtil.formatToCurrency(Number(acheieveQty));
      }
    },

    /**
     * 登録ボタン押下
     */
    rcvAchAdd() {
      let cnt = 0;
      let cntCheck = [];
      let cntChenge = [];
      if (this.$refs.editedList.validate()) {
        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].check) {
            // ロケ有効期間外の場合
            if (this.inputList[i].locationValidMsg != "") {
              return;
            }
            var checkAcheieveQty = Number(
              commonUtil.zen2han(this.inputList[i].acheieveQty).replaceAll(",", "")
            );
            var checkProductQty = Number(
              commonUtil.zen2han(this.inputList[i].productQty).replaceAll(",", "")
            );

            // 指示数と実績数の整合性チェック
            if (checkProductQty != checkAcheieveQty) {
              cnt = i + 1;
              // 実績数と指示数が一致している場合
              this.errorFlg = false;
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-ENT-002_003_E");
            } else {
              // 処理なし
            }

            // ロケーション選択有無
            if (!this.inputList[i].location) {
              cnt = i + 1;
              // 実績数が指示数を超えているレコードがある場合
              this.errorFlg = false;
              this.updateDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-ENT-002_002_E");
            } else {
              // 処理なし
            }

            if (this.inputList[i].oriAcheieveQty == this.inputList[i].acheieveQty) {
              // 修正していないレコードを探し、レコード数を数える
              cntChenge.push(this.inputList[i]);
            } else {
              // 処理なし
            }

            // 数値チェック
            if (isNaN(checkAcheieveQty)) {
              // 実績数量が数値でない時
              cnt = i + 1;
            } else {
              // 処理なし
            }

            // 整数チェック
            if (Number.isInteger(checkAcheieveQty)) {
              // 処理なし
            } else {
              // 実績数量が整数でない時
              cnt = i + 1;
            }
          } else {
            // チェックしていないレコードを数える
            cntCheck.push(this.inputList[i]);
          }
        }

        // 明細部、入力エラーなし（カウント0）かつ、修正していないレコードがinputListのレコード数と一致しない場合、確認メッセージ表示
        if (cnt == 0 && cntChenge.length == 0 && cntCheck.length != this.inputList.length) {
          this.addFlg = true;
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        } else {
          if (cntCheck.length == this.inputList.length) {
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
          } else if (cntChenge.length != 0) {
            // 修正点がなかった時
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
          } else if (this.$refs.editedList.validate()) {
            // 明細部、入力エラー時（カウント1）
            // 処理なし
          } else {
            // 処理なし
          }
        }
      } else {
        // 処理なし
      }
    },

    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].deleteFlg === "0") {
            this.inputList[i].check = true;
          }
        }
        // ロケ有効期間のチェック
        this.isAllCheck = true;
        this.checkLocationAll();
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          if (this.inputList[j].deleteFlg === "0") {
            this.inputList[j].check = false;
          }
        }
        this.isAllSelected = false;
        // ロケ有効期間のチェック
        this.isAllCheck = false;
        this.checkLocationAll();
      }
    },

    /**
     * 倉庫変更時、ロケ初期化
     */
    changeWarehouse(index) {
      this.inputList[index].location = "";
      this.inputList[index].locationHint = "";
      this.inputList[index].locationList = [];
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合
        this.inputList[index].check = true;
        // ロケ有効期間のチェック
        this.isCheck = true;
        this.checkLocationIndiv(index);
      } else {
        // チェックを外した場合
        this.inputList[index].check = false;
        // ロケ有効期間のチェック
        this.isCheck = false;
        this.checkLocationIndiv(index);
      }
    },

    /**
     * 一括反映ボタン押下処理
     */
    collectiveReflection() {
      if (this.warehouseSelected) {
        // 倉庫プルダウンで選択した倉庫をリストに一括反映する
        for (var i = 0; i < this.inputList.length; i++) {
          if (this.inputList[i].warehouse != this.warehouseSelected) {
            this.inputList[i].warehouse = this.warehouseSelected;
            this.changeWarehouse(i);
          }
        }
        // 倉庫プルダウンを初期化
        this.warehouseSelected = "";
      } else {
        // 選択されていない場合、何も処理しない
      }
    },

    /**
     * 検索処理（入荷実績差異GETAPI）
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 入荷伝票SID
      config.params.inListSid = this.inListSid;

      // 工程コード(入荷)
      config.params.procCd = "200";

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.arrivalAchieveDate);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INDIFFERENC, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getDetail() Response", response);
            if (jsonData.resCom.resComCode == "000") {
              var list = {};
              var wareInOrderMap = {};
              jsonData.resIdv.inDifference.forEach((row) => {
                if (row.wareInOrder.length == 0) {
                  return;
                }
                let difference = row.subtraction;
                list = {
                  // 入荷伝票明細SID
                  inListSubSid: row.inListSubSid,
                  // 入荷伝票明細No
                  arrivalNo: row.inListSubNo,
                  // 品番
                  itemCd: row.itemCd.substring(0, 50).trim(),
                  itemCdShow: row.itemCd,
                  //付帯
                  incidental: row.itemCd.slice(-50).trim(),
                  // 品名
                  itemName: row.itemName,
                  // 実績数量
                  acheieveQty: "0",
                  oriAcheieveQty: String(commonUtil.formatToCurrency(Number(row.actualQuantity))),
                  // 差異
                  difference: String(commonUtil.formatToCurrency(Number(difference))),
                  // 倉庫
                  displayWarehouse: row.warehouseName,
                  // ロケ
                  displayLocation: row.locationNo,
                  // 商品標準SID
                  itemStandardSid: row.itemStandardSid,
                  // 更新日時
                  updateDatetime: row.updateDatetime,
                  // 品質区分
                  qualityDiv: row.qualityDiv,
                  // チェックボックス
                  check: false,
                  // 流通加工区分
                  distDiv: row.distDiv,
                  // 前工程コード
                  aheadProcCd: row.aheadProcCd,
                  // 後工程コード
                  nextProcCd: row.nextProcCd,
                };

                // 工程実績を設定（指示）
                if (0 < row.wareInOrder.length) {
                  for (var k = 0; k < row.wareInOrder.length; k++) {
                    // 指示数量
                    wareInOrderMap.productQty = String(
                      commonUtil.formatToCurrency(Number(row.wareInOrder[k].signStock))
                    );
                    // 入庫指示
                    wareInOrderMap.wareInInstSid = row.wareInOrder[k].wareInInstSid;
                    // 管理No
                    wareInOrderMap.itemManageNo = row.wareInOrder[k].itemManageNo;
                    // 実績登録済みかの判断
                    wareInOrderMap.deleteFlg = row.wareInOrder[k].deleteFlg;

                    // ロット
                    let lotNoObj = commonUtil.convertLotNo(row.wareInOrder[k].lotNo);
                    let lotNoShowObj = commonUtil.createLotNo(
                      lotNoObj.original.lot,
                      lotNoObj.original.serial,
                      lotNoObj.original.dueDate,
                      lotNoObj.original.lotSubkey1,
                      lotNoObj.original.lotSubkey2
                    );
                    // ロット／S/N
                    wareInOrderMap.lot = lotNoObj.lot;
                    wareInOrderMap.lotShow = lotNoShowObj;
                    // 期限日／付属キー１／付属キー２
                    wareInOrderMap.dueDate = lotNoObj.dueDate;

                    // ロケ情報を設定(現状1ロケしか登録不可)
                    if (0 < row.locationReserve.length) {
                      var locationMap = {};

                      let listLoca = [];
                      for (var l = 0; l < row.locationReserve.length; l++) {
                        // ロケsearch(暫定対応用)
                        listLoca.push({
                          text: row.locationReserve[l].locationNo,
                          value: row.locationReserve[l].locationSid,
                          name: row.locationReserve[l].locationNo,
                          validFrom: row.locationReserve[l].validFrom,
                          validTo: row.locationReserve[l].validTo,
                        });
                        // ロケ検索イベント
                        locationMap.locaSearch = row.locationReserve[l].locationNo;
                        // ロケリスト
                        locationMap.locationList = listLoca;
                        // 倉庫SID
                        locationMap.warehouseSid = row.locationReserve[l].warehouseSid;
                        // 倉庫No
                        locationMap.warehouseName = row.locationReserve[l].warehouseName;
                        // 倉庫プルダウン
                        locationMap.warehouse = row.locationReserve[l].warehouseSid;
                        // ロケSID
                        locationMap.locationSid = row.locationReserve[l].locationSid;
                        // ロケ検索Hint
                        locationMap.locationHint = row.locationReserve[l].locationNo;
                        // ロケプルダウン
                        locationMap.location = row.locationReserve[l].locationSid;
                        // ロケ有無
                        locationMap.isLocation = true;
                      }
                      // 明細とロケ情報をマージする
                      var mergeLocaList = { ...list, ...locationMap, ...wareInOrderMap };
                      this.inputList.push(mergeLocaList);
                    } else if (row.locationReserve.length == 0) {
                      var locationMapNo = {};

                      // ロケリスト
                      locationMapNo.locationList = [];
                      // 倉庫SID
                      locationMapNo.warehouseSid = "";
                      // 倉庫No
                      locationMapNo.warehouseName = "";
                      // 倉庫プルダウン
                      locationMapNo.warehouse = "";
                      // ロケSID
                      locationMapNo.locationSid = "";
                      // ロケ検索Hint
                      locationMapNo.locationHint = "";
                      // ロケプルダウン
                      locationMapNo.location = "";
                      // ロケ検索イベント
                      locationMapNo.locaSearch = "";
                      // ロケ有無
                      locationMapNo.isLocation = false;

                      // 明細とロケ情報をマージする
                      var mergeLocaNoList = { ...list, ...locationMapNo, ...wareInOrderMap };
                      this.inputList.push(mergeLocaNoList);
                    }
                  }
                }
              });

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // 入荷実績取得後、バリデーション
            this.$refs.editedList.validate();
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }).then(() => {
        for (var m = 0; m < this.inputList.length; m++) {
          // 既に完了している指示に関しては実績数量を指示数量と一緒にする
          if (this.inputList[m].deleteFlg == "1") {
            this.inputList[m].oriAcheieveQty = this.inputList[m].productQty;
            // それ以外はoriAcheieveQtyを0に設定。これがないと変更がありませんエラーがでてしまう為
          } else {
            this.inputList[m].oriAcheieveQty = "0";
          }
        }

        // 品質区分、明細部にあるため編集
        this.qualityDiv = this.inputList[0].qualityDiv;
      });
      // 仕様変更前のコード（疎通確認できるまで保存）
      // 正常時
      //       if (jsonData.resCom.resComCode == "000") {

      //         for (var j = 0; j < apiData.length; j++) {
      //           // API結果：入荷予定一覧明細
      //           let apiDataItem = apiData[j];
      //           // API結果保持変数
      //           let inputListItem = {};
      //           // 入荷伝票明細SID
      //           inputListItem.inListSubSid = apiDataItem.inListSubSid;
      //           // 入荷伝票明細No
      //           inputListItem.arrivalNo = apiDataItem.inListSubNo;
      //           // 品番
      //           inputListItem.itemCd = apiDataItem.itemCd;
      //           // 品名
      //           inputListItem.itemName = apiDataItem.itemName;
      //           // ロット
      //           inputListItem.lot = apiDataItem.lotNo;
      //           // 指示数量
      //           let productQtyNum = apiDataItem.inScheduleQuantity;
      //           inputListItem.productQty = String(
      //             commonUtil.formatToCurrency(Number(productQtyNum))
      //           );
      //           // 差
      //           let difference = apiDataItem.subtraction;
      //           inputListItem.difference = String(commonUtil.formatToCurrency(Number(difference)));
      //           // 実績数量
      //           let acheieveQty = apiDataItem.actualQuantity;
      //           inputListItem.acheieveQty = String(
      //             commonUtil.formatToCurrency(Number(acheieveQty))
      //           );
      //           inputListItem.oriAcheieveQty = String(
      //             commonUtil.formatToCurrency(Number(acheieveQty))
      //           );
      //           // 倉庫
      //           inputListItem.displayWarehouse = apiDataItem.warehouseName;
      //           // ロケ
      //           inputListItem.displayLocation = apiDataItem.locationNo;
      //           // 商品標準SID
      //           inputListItem.itemStandardSid = apiDataItem.itemStandardSid;
      //           // 更新日時
      //           inputListItem.updateDatetime = apiDataItem.updateDatetime;
      //           // 品質区分
      //           inputListItem.qualityDiv = apiDataItem.qualityDiv;
      //           // チェックボックス
      //           inputListItem.check = false;
      //           inputListItem.locationList = [];
      //           inputListItem.locationHint = "";
      //           // 工程実績を設定
      //           let wareInOrderList = [];
      //           if (apiDataItem.wareInOrder) {
      //             for (var k = 0; k < apiDataItem.wareInOrder.length; k++) {
      //               var wareInOrderMap = {};
      //               // 工程実績リスト取得
      //               let wareInOrderItem = apiDataItem.wareInOrder[k];
      //               // 数量
      //               wareInOrderMap.signStock = wareInOrderItem.signStock;
      //               // 入庫指示
      //               wareInOrderMap.wareInInstSid = wareInOrderItem.wareInInstSid;
      //               // 管理No
      //               wareInOrderMap.itemManageNo = wareInOrderItem.itemManageNo;
      //               // ロケーションSID
      //               wareInOrderMap.locationSid = wareInOrderItem.locationSid;

      //               wareInOrderList.push(wareInOrderMap);
      //             }
      //           }
      //           // inputListItemにwareInOrderListを格納
      //           inputListItem.wareInOrderList = wareInOrderList;

      //           // inputListに格納
      //           this.inputList.push(inputListItem);
      //         }

      //         // 品質区分、明細部にあるため編集
      //         this.qualityDiv = this.inputList[0].qualityDiv;

      //         resolve(response);
      //       } else {
      //         // エラー時
      //         this.infoDialog.message = jsonData.resCom.resComMessage;
      //         this.infoDialog.title = appConfig.DIALOG.title;
      //         this.simpleDialog = true;
      //         this.infoDialog.firstPageFlag = true;
      //         reject();
      //       }
      //     })
      //     .catch((ex) => {
      //       this.infoDialog.message = ex;
      //       this.infoDialog.title = appConfig.DIALOG.title;
      //       this.simpleDialog = true;
      //       this.infoDialog.firstPageFlag = true;
      //       reject();
      //     })
      //     .finally(() => {
      //       // ローディング画面表示OFF
      //       this.loadingCounter = 0;
      //     });
      // });
    },

    /**
     * ロケーションの有効期間チェック(全選択時)
     */
    checkLocationAll() {
      // チェックボックス選択時
      if (this.isAllCheck) {
        // 入荷実績日
        const arrivalAchieveDate = new Date(this.arrivalAchieveDate);
        for (var i = 0; i < this.inputList.length; i++) {
          // ロケリスト1件以上かつ、ロケ有効開始、有効終了が空でない場合
          if (
            this.inputList[i].locationList.length > 0 &&
            this.inputList[i].isLocation == true &&
            this.inputList[i].locationList[0].validFrom != undefined &&
            this.inputList[i].locationList[0].validTo != undefined
          ) {
            // 有効開始日
            const validFrom = new Date(
              dateTimeHelper.convertUTC2JST(this.inputList[i].locationList[0].validFrom)
            );
            // 有効終了日
            const validTo = new Date(
              dateTimeHelper.convertUTC2JST(this.inputList[i].locationList[0].validTo)
            );
            // ロケ有効期間の場合
            if (validFrom <= arrivalAchieveDate && arrivalAchieveDate <= validTo) {
              this.inputList[i].locationValidMsg = i18n.tc("");
              // ロケ有効期間外の場合
            } else {
              this.inputList[i].locationValidMsg = i18n.tc("check.chk_inputLocationFromToValidate");
            }
            // ロケリスト0件の場合
          } else {
            this.inputList[i].locationValidMsg = i18n.tc("");
          }
        }
      } else {
        for (var j = 0; j < this.inputList.length; j++) {
          if (this.inputList[j].locationValidMsg != "") {
            // ロケ期間外エラーの初期化
            this.inputList[j].locationValidMsg = i18n.tc("");
          }
        }
      }
    },

    /**
     * ロケーションの有効期間チェック(個別選択時)
     */
    checkLocationIndiv(index) {
      // チェックボックス選択時
      if (this.isCheck) {
        // ロケリスト1件以上かつ、ロケ有効開始、有効終了が空でない場合
        if (
          this.inputList[index].locationList.length > 0 &&
          this.inputList[index].isLocation == true &&
          this.inputList[index].locationList[0].validFrom != undefined &&
          this.inputList[index].locationList[0].validTo != undefined
        ) {
          // 入荷実績日
          const arrivalAchieveDate = new Date(this.arrivalAchieveDate);
          // 有効開始日
          const validFrom = new Date(
            dateTimeHelper.convertUTC2JST(this.inputList[index].locationList[0].validFrom)
          );
          // 有効終了日
          const validTo = new Date(
            dateTimeHelper.convertUTC2JST(this.inputList[index].locationList[0].validTo)
          );
          // ロケ有効期間の場合
          if (validFrom <= arrivalAchieveDate && arrivalAchieveDate <= validTo) {
            this.inputList[index].locationValidMsg = i18n.tc("");
            // ロケ有効期間外の場合
          } else {
            this.inputList[index].locationValidMsg = i18n.tc(
              "check.chk_inputLocationFromToValidate"
            );
          }
          // ロケリスト0件の場合
        } else {
          this.inputList[index].locationValidMsg = i18n.tc("");
        }
      } else {
        if (this.inputList[index].locationValidMsg != "") {
          // ロケ期間外エラーの初期化
          this.inputList[index].locationValidMsg = i18n.tc("");
        }
      }
    },

    /**
     * 行削除処理
     */
    deleteItem(item) {
      this.editedIndex = this.inputList.indexOf(item);

      // 削除したリストを退避する。
      this.deleteList.push(this.inputList[this.editedIndex]);
      this.inputList.splice(this.editedIndex, 1);

      for (var i = 0; i < this.inputList.length; i++) {
        this.inputList[i].No = i + 1;
      }
    },

    /**
     * 倉庫取得
     */
    getWarehouse() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isMst = "0";
      config.params.searchCategory = "0";

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.MST_WAREHOUSEBIZ_USESEARCH, config)
          .then((response) => {
            // console.debug("getWarehouse() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.warehouses.forEach((row) => {
                list.push({
                  text: row.warehouseLanguage[0].warehouseName,
                  value: row.warehouseLanguage[0].warehouseSid,
                  name: row.warehouseLanguage[0].warehouseName,
                });
              });
              this.warehouseList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 倉庫セット
     */
    setWarehouse() {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == this.warehouseSelected) {
          return this.warehouseList[i].name;
        }
      }
    },

    /**
     * 倉庫リストボックスセット処理
     */
    checkWarehouse(value) {
      for (var i = 0; i < this.warehouseList.length; i++) {
        if (this.warehouseList[i].value == value.warehouse) {
          return this.warehouseList[i].name;
        }
      }
    },

    /**
     * ロケリストボックスセット処理
     */
    checkLocation(item) {
      // return this.inputList[index].locationHint;

      return item.locationHint;
    },

    /**
     * ロケリストボックス変更時メソッド
     */
    changeLocation(value, item, index) {
      // 既存ロケを選択時
      let val = this.inputList[index].locationList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        item.locationHint = val.text;
        item.location = val.value;
      }
      item.locationList = item.locationList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        item.locationSid = null;
        item.location = null;
        item.locationHint = null;
      } else {
        // 処理なし
      }

      // ロケ期間外エラーの初期化
      if (
        this.inputList[index].locationValidMsg != "" &&
        this.inputList[index].locationValidMsg != undefined &&
        this.inputList[index].isLocation == true
      ) {
        this.inputList[index].locationValidMsg = i18n.tc("");
      }
    },

    /**
     * 実績登録POSTAPI接続
     */
    deleteStock() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_ENT_002;

      // 修正ﾘｽﾄ抽出
      var bodyList = [];

      // 実績を追加したレコードのみループを回し、数量を設定。
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].deleteFlg === "1") {
          continue;
        }
        if (this.inputList[i].check) {
          // 入力した実績数
          var atualQuantity = commonUtil.zen2han(this.inputList[i].acheieveQty).replaceAll(",", "");
          var inputMap = {};
          // 指示実績数
          var atualWareInQuantity = this.inputList[i].signStock;
          // 指示実績数が0であった場合、処理抜ける
          if (atualWareInQuantity == 0) {
            break;
          }
          // 登録する実績数の計算
          var setQuantity = atualQuantity - atualWareInQuantity;

          if (atualQuantity != this.inputList[i].signStock && setQuantity == 0) {
            break;
          }
          // 操作画面名
          inputMap.opeDispName = i18n.tc("label.lbl_enteringAchieveAddpc");
          // 工程CD（入庫:200）
          inputMap.procCd = "200";
          // 入出区分（入:01）
          inputMap.inOutDiv = "01";
          // 日時
          // let today = new Date();
          // today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          var nowDate = dateTimeHelper.convertUTC();
          inputMap.yyyymmddHhmmss = nowDate;
          // 商品標準SID
          inputMap.itemStandardSid = this.inputList[i].itemStandardSid;
          // 品番
          inputMap.itemCd = this.inputList[i].itemCdShow;
          // ロット
          inputMap.lotNo = this.inputList[i].lotShow;
          // 管理No
          inputMap.itemManageNo = this.inputList[i].itemManageNo;
          // 数量
          inputMap.stock = this.inputList[i].productQty.replaceAll(",", "");
          // FromSID（入荷元SID）
          inputMap.fromSid = this.fromSid;
          // ToSID（営業所SID）
          inputMap.toSid = sessionStorage.getItem("sales_office_sid");
          // 有償無償区分
          inputMap.freeCostDiv = this.freeCostDiv;
          // 品質区分
          inputMap.qualityDiv = this.inputList[i].qualityDiv;
          // 責任区分
          inputMap.blameDiv = this.blameDiv;
          // 入荷伝票明細SID
          inputMap.inListSubSid = this.inputList[i].inListSubSid;
          // 営業所SID
          inputMap.officeSid = sessionStorage.getItem("sales_office_sid");
          // 取引先SID
          inputMap.clientSid = this.searchSuppliersSelected;
          // 予定更新日時
          inputMap.scheduleUpdateDatetime = this.updateDatetime;
          // 明細更新日時
          inputMap.detailUpdateDatetime = this.inputList[i].updateDatetime;
          // // ロケーションSID
          inputMap.locationSid = this.inputList[i].location;
          // 入庫指示SID
          inputMap.wareInInstSid = this.inputList[i].wareInInstSid;
          // 流通加工区分
          inputMap.distDiv = this.inputList[i].distDiv;
          // 前工程コード
          inputMap.aheadProcCd = this.inputList[i].aheadProcCd;
          // 後工程コード
          inputMap.nextProcCd = this.inputList[i].nextProcCd;

          bodyList.push(inputMap);
        } else {
          // 実績を追加していないレコードは、追加しない
        }
      }

      // 実績一覧に追加
      body.reqIdv.actuals = bodyList;
      // console.debug("deleteStock() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_ACTUALS, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("deleteStock() Response", response);

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // 遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.entAchSerchFlg = true;
              this.infoDialog.firstPageFlag = false;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    inputList: function (inputL) {
      inputL.forEach((value, index) => {
        this.$watch(
          () => value.locaSearch,
          (val) => {
            if (val ?? false) {
              // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
              if (val == value.locationHint) {
                return;
              }

              // 入力桁数が定義した数異常の場合検索処理
              if (val.length >= appConfig.CNTCHARITEM) {
                this.loadingCounter = 1;

                const config = this.$httpClient.createGetApiRequestConfig();
                config.params.locationNo = val;
                config.params.officeSid = sessionStorage.getItem("sales_office_sid");
                config.params.clientSid = this.searchSuppliersSelected;
                // "0"=共通の基準日時で有効期間内データを取得 "1"=すべて取得
                config.params.searchCategoryDiv = "0";
                // 処理区分
                config.params.processDiv = "01";
                // 倉庫SID
                if (this.inputList[index].warehouse) {
                  config.params.warehouseSid = this.inputList[index].warehouse;
                }
                config.params.reqComReferenceDate = dateTimeHelper.convertUTC(
                  this.arrivalAchieveDate
                );
                return new Promise((resolve, reject) => {
                  this.$httpClient
                    .secureGet(appConfig.API_URL.MST_LOCATIONBIZ, config)
                    .then((response) => {
                      const jsonData = JSON.parse(JSON.stringify(response.data));
                      // console.debug("inputList: function (inputL) Response", response);

                      if (jsonData.resCom.resComCode == "000") {
                        const list = [];
                        jsonData.resIdv.locations.forEach((row) => {
                          list.push({
                            text: row.locationNo,
                            value: row.locationSid,
                            name: row.locationNo,
                          });
                        });
                        inputL[index].locationList = list;
                        resolve(response);
                      } else {
                        this.infoDialog.message = jsonData.resCom.resComMessage;
                        this.infoDialog.title = appConfig.DIALOG.title;
                        this.simpleDialog = true;
                        // OK NGボタン
                        this.infoDialog.firstPageFlag = true;
                        reject();
                      }
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.simpleDialog = true;
                      // OK NGボタン
                      this.infoDialog.firstPageFlag = true;
                      reject();
                    })
                    .finally(() => {
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                    });
                });
              } else {
                inputL[index].locationList.length = 0;
              }
            }
          }
        );
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
.table-warehouse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
  .disabled {
    pointer-events: none;
  }
}
</style>
